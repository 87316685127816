import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import arrowDown from '../../assets/arrow-down.png';
import arrowUp from '../../assets/arrow-up.png';
import { ReactComponent as Check } from '../../assets/check.svg';
import clock from '../../assets/clock.png';
import { Header } from '../../components/header';
import { convertCampaignParameter } from '../../utils';

import { useRecoilValue } from 'recoil';
import { customState, platformState } from 'recoil/atoms/campaignState';
import { sendMessageToSDK } from 'utils/send-message-to-sdk';
import { CampaignJoinButton } from '../../components/campaign-join-button';

export default function DetailPage({ campaign, onClose }) {
  const [showAllSubCampaigns, setShowAllSubCampaigns] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const platform = useRecoilValue(platformState);
  const custom = useRecoilValue(customState);

  const Campaign = location.state?.campaign;

  if (!Campaign) return null;

  const multiRewardCampaignType = [61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73];
  const isMultiRewardType = multiRewardCampaignType.includes(Campaign.PartiTypeCode);

  const joinCampaign = () => {
    // if (!window) return;
    // if (!window?.APRewardOfferwall) return;
    // window?.APRewardOfferwall?.joinCampaign(
    //   `"${JSON.stringify(convertCampaignParameter(Campaign))}"`
    // );
    sendMessageToSDK(
      'joinCampaign',
      { jsonParameter: `"${JSON.stringify(convertCampaignParameter(Campaign))}"` },
      platform
    );
  };

  const { FeedMainImgURL, FeedDetailImgURL, IconImgURL } = Campaign;

  const imgSrc = FeedDetailImgURL || FeedMainImgURL;
  return (
    <div
      style={{ height: window.innerHeight }}
      className="h-[100vh] absolute left-0 top-0 z-10 bg-[#F8F9FA] m-auto max-w-[900px] overscroll-y-none overflow-x-hidden scroll-smooth"
    >
      <Header title={Campaign.FeedTitle} _onClick={() => navigate(-1)} />
      <div className="bg-white border-b-[1px] border-solid border-[#F1F2F4] h-[calc(100%-44px)] overflow-y-scroll ">
        {imgSrc && (
          <img
            style={{ borderStyle: 'none' }}
            alt="FeedImg"
            width="100%"
            height="auto"
            src={imgSrc}
            key={imgSrc}
          />
        )}
        <div className="px-[16px]">
          <div className="flex flex-col gap-[8px] py-[24px]">
            {Campaign?.MultiRewardConditionDescription && (
              <div
                style={{
                  color: custom.primaryColor,
                }}
                className={`text-[12px] leading-[16px] py-[6px] px-[12px] border border-[#D8E9FD] rounded-[999px] w-fit`}
              >
                {Campaign?.MultiRewardConditionDescription}
              </div>
            )}
            <div className="flex gap-[10px] items-center">
              {IconImgURL && !imgSrc && (
                <img
                  style={{ borderStyle: 'none' }}
                  alt="iconImg"
                  width="60px"
                  height="60px"
                  src={IconImgURL}
                  key={IconImgURL}
                />
              )}

              <div className="text-[18px] whitespace-pre-wrap tracking-[-0.5px] text-[#212529] leading-[23.4px] font-semibold text-start">
                {Campaign.FeedTitle}
                <br />
                {Campaign.FeedDesc}
                <span
                  style={{
                    color: custom.primaryColor,
                  }}
                  className="font-semibold"
                >
                  {Campaign.Reward}
                </span>
                <span> 지급!</span>
              </div>
            </div>
            {Campaign?.SubCampaigns && (
              <div className="py-[24px]">
                <div className="p-[20px] text-[14px] flex flex-col gap-[24px] border border-gray-50 rounded-[16px]">
                  {[
                    ...(showAllSubCampaigns
                      ? Campaign.SubCampaigns
                      : Campaign.SubCampaigns.slice(0, 3)),
                  ].map((sub) => (
                    <div className="flex justify-between" key={sub.Description}>
                      <div className="flex gap-[8px]">
                        <Check stroke={sub.Complete ? '#D5D9DD' : custom.primaryColor} />
                        <div className={`${sub.Complete ? 'text-gray-400' : 'text-gray-800'}`}>
                          {sub.Description}
                        </div>
                      </div>
                      <div
                        style={{ color: !sub.Complete && custom.primaryColor }}
                        className={`${sub.Complete ? 'text-gray-400' : `font-[500]`}`}
                      >
                        {sub.Complete ? '완료' : sub.Reward}
                      </div>
                    </div>
                  ))}
                  <div className="border-t border-gray-50 h-[36px] flex justify-center items-end">
                    <div
                      className="flex justify-center items-center"
                      role="presentation"
                      onClick={() => setShowAllSubCampaigns(!showAllSubCampaigns)}
                    >
                      <div className="text-gray-800">
                        {Campaign?.SubCampaigns?.filter((sub) => sub.Complete).length}&nbsp;
                      </div>
                      <div className="text-gray-400">
                        &nbsp;/&nbsp;{Campaign?.SubCampaigns?.length}&nbsp;
                      </div>
                      <div>참여 완료</div>
                      <img
                        src={showAllSubCampaigns ? arrowUp : arrowDown}
                        alt="icon"
                        className="w-[16px] h-[16px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isMultiRewardType && Campaign?.MultiRewardDurationDescription && (
              <div
                style={{
                  color: custom.primaryColor,
                }}
                className={`bg-[#F0F7FE] rounded-full flex gap-[8px] justify-center items-center py-[16px] px-[20px] text-[14px] leading-[20px] font-500`}
              >
                <img src={clock} alt="clock" />
                <div>{Campaign?.MultiRewardDurationDescription}</div>
              </div>
            )}
            {Campaign?.DetailRewardConditionMessageList && (
              <ul className="list-inside list-disc text-[#808C99] font-[400] tracking-[-0.4px] leading-[18.2px] text-[13px] flex flex-col gap-[2px] justify-start text-start relative py-[24px]">
                {Campaign.DetailRewardConditionMessageList?.map((message) => {
                  return <li key={message}>{message}</li>;
                })}
              </ul>
            )}
          </div>

          {Campaign?.ParticipationStepMessageList && (
            <div className="flex flex-col">
              <div className="h-[8px] w-full bg-[#F8F9FA]"></div>
              <div className="py-[24px] flex flex-col gap-[24px] tracking-[-0.5px]">
                {Campaign?.ParticipationStepMessageList?.map((message, idx) => {
                  return (
                    <div className="flex flex-col gap-[6px] items-start text-[14px]" key={message}>
                      <span className="text-[#212529] font-medium leading-[18.2px]">
                        STEP {idx + 1}
                      </span>
                      <span className="text-[#66737F] leading-[16.8px] font-[400]">{message}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <Notification />
        <CampaignJoinButton joinCampaign={joinCampaign} />
      </div>
    </div>
  );
}

const NotificationList = [
  '캠페인 미션 완료 시 리워드가 지급되며, 기존 이벤트 참여 이력이 있는 경우 리워드가 지급되지 않습니다.',
  '네트워크 장애가 발생한 경우, 리워드 지급이 지연될 수 있습니다.',
  '캠페인 참여를 통해 지급된 리워드는 재전환 및 전환 취소되지 않으며, 유효기간 내 미사용 리워드는 자동 소멸될 수 있습니다.',
  '리워드의 종류 및 지급 수량은 회사 정책에 의해 변경될 수 있습니다.',
  'Wi-Fi 환경이 아닌 경우 데이터 이용료가 발생할 수 있습니다.',
  '본 캠페인은 사정에 따라 사전 공지 없이 내용이 수정되거나 종료될 수 있습니다.',
  '본 캠페인은 (주)애드팝콘이 제공합니다.',
  '캠페인 문의는 고객 센터를 통해 문의 가능합니다.',
];

const Notification = () => {
  return (
    <div className="bg-[#F8F9FA]">
      <ul className="h-fit px-[16px] tracking-[-0.5px] flex font-[400] flex-col gap-[4px] justify-start text-start relative list-disc list-inside text-[13px] leading-[18.2px] text-[#808C99] py-[40px]">
        {NotificationList.map((item) => {
          return <li key={item}>{item}</li>;
        })}
      </ul>
    </div>
  );
};
