import axios from 'axios';

// 기본 설정을 가진 인스턴스 생성
const defaultInstance = axios.create({
  // baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000, // 타임아웃 시간을 밀리초 단위로 설정 (10초)
});

// baseURL을 인수로 받아 새로운 인스턴스 생성하는 함수
const createCustomInstance = (baseURL) => {
  const instance = axios.create({
    ...defaultInstance.defaults,
    baseURL: baseURL,
  });

  // 인터셉터 설정
  instance.interceptors.response.use(
    (response) => {
      console.log(response, '??');
      // 응답이 성공적으로 왔을 때
      if (response?.data && response?.data?.Result === false) {
        const errMsg =
          response?.data?.ResultCode === 200
            ? '알 수 없는 오류가 발생 하였습니다.'
            : response?.data?.ResultCode === 980
            ? '현재 캠페인의 참여 한도가 소진되어 참여할 수 없습니다.'
            : response?.data?.ResultMsg;

        const error = new Error(errMsg);

        error.code = response?.data?.ResultCode; // 에러 코드 포함
        throw error;
      }
      return response.data;
    },
    (error) => {
      console.log(error, 'err from  axios instance');
      // 응답이 실패했을 때
      let errorMessage = '일시적인 네트워크 오류가 발생하였습니다.\n광고를 새로고침 해주세요.';

      if (axios.isAxiosError(error) && error.response) {
        errorMessage = error.response.data.ResultMsg || errorMessage;
      }

      // 오류를 반환하는 대신 오류 메시지를 반환하여 요청 처리 중단 방지
      return Promise.reject(new Error(errorMessage));
    }
  );

  return instance;
};

// 다양한 baseURL을 가진 인스턴스 생성
const liveInstance = createCustomInstance(process.env.REACT_APP_API_URL);
const csLiveInstance = createCustomInstance(process.env.REACT_APP_CS_API_URL);
const stgInstance = createCustomInstance(process.env.REACT_APP_STG_API_URL);
const csStgInstance = createCustomInstance(process.env.REACT_APP_CS_STG_API_URL);
const csV2LiveInstance = createCustomInstance(process.env.REACT_APP_CS_V2_API_URL);
const csV2stgInstance = createCustomInstance(process.env.REACT_APP_CS_V2_STG_API_URL);

export {
  csLiveInstance,
  csStgInstance,
  csV2LiveInstance,
  csV2stgInstance,
  liveInstance,
  stgInstance,
};
