import { atom } from 'recoil';

// export const blockedCPMContentIdList = atom({
//   key: 'blockedCPMContentId',
//   default: [],
// });

// Recoil을 사용하여 전역적으로 상태를 관리하는 상태
export const customState = atom({
  key: 'customState',
  default: {
    offerwallTitle: '리워드 충전소',
    primaryColor: '#2685F4', // 기본값은 Tailwind CSS의 기본 primary 색상
    startTabIndex: 0,
  },
});

export const completedRcpcList = atom({ key: 'compltetedRcpcList', default: [] });

export const NoAdModalState = atom({ key: 'noAd', default: false });

export const currencyState = atom({
  key: 'currencyState',
  default: null,
});

export const platformState = atom({
  key: 'platformState',
  default: null,
});

export const RVFloatingState = atom({
  key: 'RVFloatingState',
  default: false,
});

export const RVInfoListState = atom({
  key: 'RVInfoList',
  default: [],
});

export const blockedCampaignList = atom({
  key: 'blockedCampaign',
  default: [],
});

export const isTestState = atom({
  key: 'isTest',
  default: false,
});

export const mediaKeyState = atom({
  key: 'mediaKey',
  default: null,
});

export const parameterState = atom({
  key: 'parameter',
  default: null,
});

export const choiceOfferCampaignState = atom({
  key: 'choiceOffer',
  default: null,
});

export const selectedCampaignState = atom({
  key: 'selectedCampaign',
  default: null,
});

export const totalRewardState = atom({
  key: 'totalReward',
  default: 0,
});

export const csState = atom({
  key: 'csPageData',
  default: { csHistory: {}, csCampaign: [], ongoingCampaigns: [] },
});

export const campaignListState = atom({
  key: 'campaignList',
  default: [],
});

export const cpmCampaignListState = atom({
  key: 'cpmCampaignList',
  default: [],
});

export const tabListState = atom({
  key: 'tabListState',
  default: [],
});

export const selectedTabState = atom({
  key: 'selectedTabState',
  default: { selectedTabId: null, selectedSubTabData: {} },
});
