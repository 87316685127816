function Timer({ secondsDifference }) {
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes}:${remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds}`;
  };

  return (
    <div>
      <h1>{formatTime(secondsDifference)}</h1>
    </div>
  );
}

export default Timer;
