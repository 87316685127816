import {
  csLiveInstance,
  csStgInstance,
  csV2LiveInstance,
  csV2stgInstance,
  liveInstance,
  stgInstance,
} from './instance';

export function requestOngoingCampaigns(params, isTest) {
  const instance = isTest ? stgInstance : liveInstance;
  return instance.post('sdk/media/ongoingcampaign', JSON.stringify(params));
}

export function requestCampaignHistory(params, isTest) {
  const instance = isTest ? csStgInstance : csLiveInstance;
  return instance.post('/sdk/media/CampaignHistory', JSON.stringify(params));
}

export function requestCSCampaign(params, isTest) {
  const instance = isTest ? csStgInstance : csLiveInstance;
  return instance.post('/sdk/media/CSCampaign', JSON.stringify(params));
}

export function addCSRequest(params, isTest) {
  const instance = isTest ? csV2stgInstance : csV2LiveInstance;

  return instance.post('/sdk/media/AddCS', params, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}
