import React from 'react';

const NotFoundPage = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <h1>페이지를 찾을 수 없습니다.</h1>
    </div>
  );
};

export default NotFoundPage;
