import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { DetailPage } from './pages/detail-page';
import { MainPage } from './pages/main-page';

import { CSPage, CSRequestForm } from './pages/cs-page';
import { NotFoundPage } from './pages/not-found-page';

import { ErrorModalProvider } from './context/error-modal-provider';

function App() {
  return (
    <ErrorModalProvider>
      <Router>
        <Routes>
          <Route path="/SDK/detail" element={<DetailPage />} />
          <Route path="/cs" element={<CSPage />} />
          <Route path="/cs-request" element={<CSRequestForm />} />
          <Route path="/SDK/list" element={<MainPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </ErrorModalProvider>
  );
}

export default App;
