import { liveInstance } from './instance';

function getTabInfo(mediaKey, params) {
  const { adid, idfa } = params;

  return liveInstance.get(
    `/sdk/media/tabInfo?mediaKey=${mediaKey || ''}&adid=${adid || ''}&idfa=${idfa || ''}`
  );
}

export default getTabInfo;
