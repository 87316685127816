import { useEffect, useState } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';
import { convertCampaignParameter, isToday } from 'utils';
import { getTabInfo } from '../apis';

import { sendMessageToSDK } from 'utils/send-message-to-sdk';

import { useErrorModal } from '../context';
import {
  campaignListState,
  choiceOfferCampaignState,
  customState,
  isTestState,
  mediaKeyState,
  parameterState,
  platformState,
  selectedCampaignState,
  selectedTabState,
  tabListState,
} from '../recoil/atoms/campaignState';
import { CampaignList } from './campaign-list';

export function TabSwitcher() {
  const { openModal } = useErrorModal();
  const mediaKey = useRecoilValue(mediaKeyState);
  const displayTime = localStorage.getItem('lastChoiceOfferDisplayDate');
  const [isTest, setIsTest] = useRecoilState(isTestState);
  const [selectedCampaign, setSelectedCampaign] = useRecoilState(selectedCampaignState);
  const [platform, setPlatform] = useRecoilState(platformState);
  const [campaignList, setCampaignList] = useRecoilState(campaignListState);
  const parameter = useRecoilValue(parameterState);
  const custom = useRecoilValue(customState);

  const [selectedTab, setSelectedTab] = useRecoilState(selectedTabState);
  const [tabList, setTabList] = useRecoilState(tabListState);
  const [lastChoiceOfferDisplayTime, setLastChoiceOfferDisplayTime] = useState(displayTime);
  const [choiceOfferCampaign, setChoiceOfferCampaign] = useRecoilState(choiceOfferCampaignState);

  useEffect(() => {
    const selectedTabId = selectedTab?.selectedTabId?.toString() || '';
    if (!selectedTabId) return;

    sendMessageToSDK(
      'logging',
      {
        eventName: 'impression_offerwall_tab',
        eventParam: selectedTabId,
      },
      platform
    );
  }, [selectedTab?.selectedTabId]);

  const showPopup =
    (!lastChoiceOfferDisplayTime && !!choiceOfferCampaign) ||
    (!isToday(new Date(lastChoiceOfferDisplayTime)) && !!choiceOfferCampaign);

  const checkInstalledPackageList = (campaign) => {
    setSelectedCampaign(campaign);

    if (campaign?.TargetPackageName?.length || campaign?.DetargetPackageName?.length) {
      // return window?.APRewardOfferwall?.checkCampaignPackageTargetList(
      //   campaign?.TargetPackageName || [],
      //   campaign?.DetargetPackageName || []
      // );
      return sendMessageToSDK(
        'checkCampaignPackageTargetList',
        {
          targetPackageNameList: campaign?.TargetPackageName || [],
          detargetPackageNameList: campaign?.DetargetPackageName || [],
        },
        platform
      );
    } else checkOrJoinCampaign(campaign);
  };

  const checkOrJoinCampaign = (campaign) => {
    const campaignParams = convertCampaignParameter(campaign);
    const partiNo = campaign?.PartiTypeCode;
    const joinCampaignType = [43, 46, 52, 53];
    // const multiRewardCampaignType = [61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73];

    // if (!window) return;
    // if (!window?.APRewardOfferwall) return;

    if ([...joinCampaignType].includes(partiNo)) {
      return sendMessageToSDK(
        'joinCampaign',
        { jsonParameter: `"${JSON.stringify(campaignParams)}"` },
        platform
      );
      // return window?.APRewardOfferwall?.joinCampaign(`"${JSON.stringify(campaignParams)}"`);
    }

    // window?.APRewardOfferwall?.checkCampaign(`"${JSON.stringify(campaignParams)}"`);
    sendMessageToSDK(
      'checkCampaign',
      { jsonParameter: `"${JSON.stringify(campaignParams)}"` },
      platform
    );
  };

  useEffect(() => {
    async function fetch() {
      try {
        const tabInfoData = await getTabInfo(mediaKey, parameter);
        const tabInfo = tabInfoData?.MediaTabInfo || [];

        setTabList(tabInfo);

        const initialState = tabInfo?.reduce(
          (acc, cur, index) => {
            if (custom.startTabIndex === index) {
              acc.selectedTabId = cur.TypeNo;
            }
            acc.selectedSubTabData[cur.TypeNo] = cur.SubTabInfo?.length
              ? cur.SubTabInfo.find((subTab) => subTab.IncludeParticipationNo.includes('0')).TypeNo
              : null;

            return acc;
          },
          { selectedTabId: 0, selectedSubTabData: {} }
        );

        if (initialState) {
          setSelectedTab(initialState);
        }
      } catch (err) {
        // window?.APRewardOfferwall?.onOfferwallLoadFailed();
        sendMessageToSDK('onOfferwallLoadFailed', null, platform);
        openModal(err.message || '');
      }
    }
    if (!mediaKey || !parameter) return;
    if (tabList?.length !== 0) return;
    fetch();
  }, [mediaKey, parameter]);

  // if (isLoading) return null;
  if (tabList.length === 0) return null;

  const { selectedSubTabData, selectedTabId } = selectedTab;
  const selectedTabInfo = campaignList?.find((tab) => tab.TypeNo === selectedTab.selectedTabId);

  // 현재 날짜가 주어진 날짜와 동일한지 확인하는 함수

  const onClosePopup = () => {
    const currentDate = new Date();
    setLastChoiceOfferDisplayTime(currentDate.toISOString());
    localStorage.setItem('lastChoiceOfferDisplayDate', currentDate.toISOString());
  };

  return (
    <div className="w-full h-full flex flex-col relative">
      {isTest && (
        <span
          style={{
            color: custom.primaryColor,
          }}
          className={`absolute top-[calc(50%)] left-[calc(50%-30px)] z-30`}
        >
          Test Mode
        </span>
      )}

      <div className="sticky top-0 bg-white z-10">
        <div className="h-[45px] grid grid-cols-[repeat(auto-fit,minmax(40px,1fr))] text-[16px] leading-[22.4px] border-b border-gray-100 font-400 break-keep overflow-x-scroll">
          {tabList?.map((tab) => (
            <div
              key={tab.TypeNo}
              role="presentation"
              onClick={() => {
                setSelectedTab((prev) => {
                  return { ...prev, selectedTabId: tab.TypeNo };
                });
              }}
              className={`py-[10px] flex justify-center items-center ${
                tab.TypeNo === selectedTabId
                  ? 'text-gray-900 font-bold border-b-[2px] border-gray-900'
                  : 'text-gray-500'
              }`}
            >
              {tab.Name}
            </div>
          ))}
        </div>

        {selectedTabInfo?.SubTabInfo?.length > 0 && (
          <div className="flex gap-[10px] text-[14px] tracking-[-0.5px] py-[10px] px-[15px] font-400 whitespace-nowrap w-full overflow-x-auto">
            {selectedTabInfo?.SubTabInfo?.map((subTab) => (
              <div
                className={`py-[4px] px-[10px] rounded-[6px] ${
                  subTab.TypeNo === selectedSubTabData[selectedTabId]
                    ? 'text-white bg-gray-900'
                    : 'text-gray-500 bg-[#F8F9FA]'
                }`}
                key={subTab.TypeNo}
                role="presentation"
                onClick={() => {
                  setSelectedTab((prev) => {
                    return {
                      ...prev,
                      selectedSubTabData: {
                        ...prev.selectedSubTabData,
                        [prev.selectedTabId]: subTab.TypeNo,
                      },
                    };
                  });
                }}
              >
                {subTab.Name}
              </div>
            ))}
          </div>
        )}
      </div>

      {tabList.length > 0 && (
        <CampaignList
          checkInstalledPackageList={checkInstalledPackageList}
          checkOrJoinCampaign={checkOrJoinCampaign}
        />
      )}

      {showPopup && (
        <div className="w-full h-[100vh] bg-[rgba(0,0,0,0.5)] z-20 absolute bottom-0 left-0 overflow-hidden px-[30px] flex justify-center items-center">
          <div className="bg-white w-full absolute bottom-0 rounded-t-[10px] flex flex-col">
            <img
              src={choiceOfferCampaign.FeedMainImgURL}
              alt="초이스오퍼 이미지"
              className="rounded-t-[10px]"
            />
            <div className="p-[16px] flex flex-col gap-[16px]">
              <div className="flex gap-[8px]">
                <img
                  src={choiceOfferCampaign?.IconImgURL || ''}
                  alt="초이스오퍼 아이콘 이미지"
                  className="w-[60px] h-[60px] rounded-[8px]"
                />
                <div className="flex flex-col gap-[2px]">
                  <span className="text-[16px] text-[#212529] leading-[20.8px]">
                    {choiceOfferCampaign?.FeedTitle || ''}
                  </span>
                  <span className="text-[14px] text-[#808C99] leading-[16.8px]">
                    {choiceOfferCampaign?.FeedDesc || ''}
                  </span>
                </div>
              </div>
              <div className="flex gap-[10px]" role="presentation" onClick={onClosePopup}>
                <button className="flex justify-center items-center w-[50%] h-[46px] rounded-[8px] bg-[#F1F2F4] text-[#9CA6AF]">
                  닫기
                </button>
                <button
                  style={{
                    backgroundColor: custom.primaryColor,
                  }}
                  className={`w-full flex justify-center items-center h-[46px] rounded-[8px] text-white`}
                  onClick={() => checkInstalledPackageList(choiceOfferCampaign)}
                >
                  {choiceOfferCampaign?.Reward}
                  적립받기
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TabSwitcher;
