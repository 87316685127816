import { useRecoilValue } from 'recoil';
import { customState } from 'recoil/atoms/campaignState';

export const Modal = ({ modalContent, pos }) => {
  const isOneButton = modalContent?.center;
  const custom = useRecoilValue(customState);

  return (
    <div
      className={`w-full h-[100vh] bg-[rgba(0,0,0,0.5)] z-20 ${
        pos ? pos : 'absolute'
      } bottom-0 left-0 overflow-hidden px-[30px] flex justify-center items-center`}
    >
      <div className="w-[300px] rounded-[14px] bg-white p-[16px]">
        <div className="text-gray-900 text-[18px] leading-[27px] flex justify-center">알림</div>
        <div className="pt-[8px] pb-[16px] text-[14px] text-gray-600 leading-[21px] flex justify-center items-center whitespace-pre-line text-center">
          {modalContent?.ResultMessage || ''}
        </div>

        {!isOneButton && (
          <div className="flex gap-[10px]">
            <button
              className="flex justify-center items-center w-[50%] h-[46px] rounded-[8px] bg-[#F1F2F4] text-[#9CA6AF]"
              onClick={modalContent?.left?.func}
            >
              {modalContent?.left?.text}
            </button>
            <button
              style={{
                backgroundColor: custom.primaryColor,
              }}
              onClick={modalContent?.right?.func}
              className={`flex justify-center items-center w-[50%] h-[46px] rounded-[8px] text-white`}
            >
              {modalContent?.right?.text}
            </button>
          </div>
        )}
        {isOneButton && (
          <button
            style={{
              backgroundColor: custom.primaryColor,
            }}
            className={`w-full flex justify-center items-center h-[46px] rounded-[8px] text-white`}
            onClick={modalContent?.center?.func}
          >
            {modalContent?.center?.text}
          </button>
        )}
      </div>
    </div>
  );
};

export default Modal;
