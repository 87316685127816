import { useRecoilValue } from 'recoil';
import { customState } from 'recoil/atoms/campaignState';

export const CampaignJoinButton = ({ joinCampaign }) => {
  const custom = useRecoilValue(customState);
  return (
    <div className="flex justify-center items-center h-[50px]">
      <div
        style={{
          backgroundColor: custom.primaryColor,
        }}
        className="shadow-[0px_4px_12px_0px_#07182D29] rounded-[16px] text-[16px] h-[54px] max-w-[900px] w-[90%] bottom-[20px] fixed text-white flex justify-center items-center"
        role="presentation"
        onClick={joinCampaign}
      >
        참여하고 적립받기
      </div>
    </div>
  );
};
