export function sendMessageToSDK(action, params, platform) {
  if (platform === 'ios') {
    const message = {};

    message.action = action;

    // params가 있는 경우 각 key-value 쌍을 추가
    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        message[key] = value;
      });
    }

    return window?.webkit?.messageHandlers?.APRewardOfferwall?.postMessage?.(message);
  } else {
    if (params) {
      // Android에서는 키 필요없이 값만 쏙쏙 뽑아서 메서드 호출
      const values = Object.values(params);
      return window?.APRewardOfferwall?.[action]?.(...values);
    } else {
      return window?.APRewardOfferwall?.[action]?.();
    }
  }
}
