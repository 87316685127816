export function isToday(someDate) {
  if (!someDate) return false;
  let date = typeof someDate === 'string' ? new Date(someDate) : someDate;

  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}
