// import { RVAd } from 'components/RV-ad';
import { TabSwitcher } from 'components';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { sendMessageToSDK } from 'utils/send-message-to-sdk';
import { Header } from '../../components/header';
import { customState, mediaKeyState, platformState } from '../../recoil/atoms/campaignState';
import { UnauthorizedPage } from '../unauthorized-page';

export function MainPage() {
  const location = useLocation();

  const [mediaKey, setMediaKey] = useRecoilState(mediaKeyState);
  const [platform, setPlatform] = useRecoilState(platformState);
  const [custom, setCustom] = useRecoilState(customState);

  const queryParams = new URLSearchParams(location.search + location.hash);

  const Agreement = queryParams.get('agreement');
  const Platform = queryParams.get('platform');
  const mediakey = queryParams.get('mediakey');

  const primaryColor = queryParams.get('primaryColor');
  const startTabIndex = queryParams.get('startTabIndex');
  const offerwallTitle = queryParams.get('offerwallTitle');

  const isAgreed = Agreement === 'true';

  useEffect(() => {
    setCustom((prev) => {
      return {
        offerwallTitle: offerwallTitle || prev.offerwallTitle,
        primaryColor: primaryColor || prev.primaryColor,
        startTabIndex: startTabIndex ? parseInt(startTabIndex) : prev.startTabIndex,
      };
    });
  }, []);

  useEffect(() => {
    if (!Platform) return;
    setPlatform(Platform || 'android');
    sendMessageToSDK('getParameter', null, Platform || 'android');
  }, [Platform]);

  useEffect(() => {
    if (mediakey) {
      setMediaKey(parseInt(mediakey));
    }
  }, [mediakey]);

  if (!isAgreed) return <UnauthorizedPage />;
  if (!platform) return null;

  return (
    <div className="bg-white h-full w-full overflow-hidden flex flex-col touch-none">
      <Header />
      <TabSwitcher />
    </div>
  );
}

export default MainPage;
