import RV from 'assets/rv.png';
import Timer from 'components/timer';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { sendMessageToSDK } from 'utils/send-message-to-sdk';
import {
  RVFloatingState,
  RVInfoListState,
  customState,
  platformState,
} from '../recoil/atoms/campaignState';

export const RVAd = () => {
  const platform = useRecoilValue(platformState);
  const [isShrinking, setIsShrinking] = useState(false);

  const [rvFloatingState, setRVFloatingState] = useRecoilState(RVFloatingState);
  const [rvInfoListState, setRVInfoListState] = useRecoilState(RVInfoListState);
  const custom = useRecoilValue(customState);
  const [time, setTime] = useState(0); // 초로 계산된 초기 시간 (4시간)

  const rvFloatingAd = rvInfoListState?.filter((rv) => rv.PlacementTypeNo === 1)?.[0];

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        return;
      } else {
        setRVFloatingState(true);
        // 페이지가 활성화됨
        // 활성화 상태에서 할 작업을 여기에 추가
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (rvFloatingAd?.IsAvailable) return;
    setRVFloatingState(true);
    const completeDateTime = new Date(rvFloatingAd?.AvailableDateTime);
    const currentDateTime = new Date();

    const timeDifference = completeDateTime - currentDateTime;

    const secondsDifference = timeDifference && Math.floor(timeDifference / 1000);
    setTime(secondsDifference);
  }, [rvFloatingAd?.AvailableDateTime]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShrinking(true);
    }, 1000);

    return () => {
      clearTimeout(timer);
    }; // 컴포넌트가 언마운트될 때 타이머 해제},[])
  }, []);

  useEffect(() => {
    if (time <= 0) return;
    const timer = setInterval(() => {
      setTime((prevTime) => prevTime - 1); // 1초씩 감소
    }, 1000);

    return () => clearTimeout(timer); // 컴포넌트가 언마운트될 때 타이머 해제
  }, [time]);

  const showTimer = time > 0 && time <= 300;

  const [position, setPosition] = useState(() => ({
    x: 0,
    y: window.innerHeight - 48 - 60 - 44,
  }));

  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setOffset({
      x: e.touches[0].clientX - position.x,
      y: e.touches[0].clientY - position.y,
    });
  };

  const handleTouchMove = (e) => {
    if (isDragging) {
      const newX = e.touches[0].clientX - offset.x;
      const newY = e.touches[0].clientY - offset.y;

      // 화면 경계를 벗어나지 않도록 처리
      const minX = 0; // 화면의 최소 가로 위치
      const minY = 0; // 화면의 최소 세로 위치
      const maxX = window.innerWidth - 48 - 60; // 원의 너비
      const maxY = window.innerHeight - 48 - 60; // 원의 높이

      const clampedX = Math.max(minX, Math.min(newX, maxX));
      const clampedY = Math.max(minY, Math.min(newY, maxY));

      setPosition({ x: clampedX, y: clampedY });
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const handleClickRVAd = () => {
    if (showTimer) return null;

    return sendMessageToSDK(
      'loadRewardVideo',
      {
        appKey: rvFloatingAd?.IntegrationAppKey || '',
        placementId: rvFloatingAd?.IntegrationPlacementId || '',
        showProgress: true,
      },
      platform
    );
  };

  if (!rvFloatingAd || time > 300) return null;

  if (rvFloatingState) {
    return (
      <div className="w-full h-[100vh] absolute bottom-0 left-0 pointer-events-none">
        <div
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          role="presentation"
          disabled={showTimer}
          onClick={handleClickRVAd}
          style={{
            left: position.x,
            top: position.y,
            backgroundColor: showTimer ? '#F1F2F4' : custom.primaryColor,
          }}
          className={`${
            showTimer ? 'text-[#B8BFC6]' : `text-white`
          } m-[30px] absolute flex flex-col justify-center items-center rounded-full text-[12px] w-[48px] h-[48px] z-40 pointer-events-auto`}
        >
          <img src={RV} alt="포인트 아이콘" className="w-[16px] h-[16px] rounded-full" />
          {showTimer && <Timer secondsDifference={time} />}
          {!showTimer && (
            <span className={`text-[16px] leading-[24px] font-[600] text-white`}>
              +{rvFloatingAd?.Point}
            </span>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`w-[100vw] h-[100vh] absolute bg-[rgba(0,0,0,0.5)] z-30 bottom-0 left-0 overflow-hidden flex justify-center items-center touch-none`}
    >
      <div
        onTransitionEnd={(e) => {
          setRVFloatingState(true);
        }}
        style={{
          backgroundColor: custom.primaryColor,
        }}
        className={`m-[30px] flex flex-col justify-center items-center rounded-full transition-all duration-[1500ms] transform ${
          isShrinking
            ? 'w-[48px] h-[48px] translate-x-[calc(-50vw+48px)] translate-y-[calc(50vh-48px)]'
            : 'w-[96px] h-[96px]'
        }`}
      >
        <img
          src={RV}
          alt="포인트 아이콘"
          style={{
            backgroundColor: custom.primaryColor,
          }}
          className={`transition-all duration-[1500ms] rounded-full ${
            isShrinking ? 'w-[16px] h-[16px]' : 'w-[32px] h-[32px]'
          }`}
        />

        <span
          className={`transition-all duration-[1500ms] text-white text-[${
            isShrinking ? '16' : '24'
          }px] leading-[24px] font-[600]`}
        >
          +{rvFloatingAd?.Point}
        </span>
      </div>
    </div>
  );
};
