import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { customState, platformState } from 'recoil/atoms/campaignState';
import { sendMessageToSDK } from 'utils/send-message-to-sdk';
import arrowBack from '../assets/arrow_back.png';
import help from '../assets/help.png';

// const CSModalButton = lazy(() => import('../components/cs-modal-button'));

export function Header({ title, _onClick }) {
  const platform = useRecoilValue(platformState);
  const custom = useRecoilValue(customState);

  const onClick = () => {
    // if (!window) return;
    // if (!window?.APRewardOfferwall) return;

    // window?.APRewardOfferwall?.closeOfferwall();

    sendMessageToSDK('closeOfferwall', null, platform);
  };

  return (
    <div className="h-[44px] flex w-[100vw] items-center justify-between px-[15px] py-[10px] bg-white z-20">
      <div
        className="flex flex-row gap-[6px] w-fit"
        onClick={(e) => {
          if (_onClick && typeof _onClick === 'function') {
            return _onClick();
          }
          onClick();
        }}
        role="presentation"
      >
        <img width={24} height={24} src={arrowBack} alt="뒤로가기" />
        <div className="text-[18px] leading-[25.2px] text-gray-900 truncate font-[600]">
          {title || custom.offerwallTitle}
        </div>
      </div>

      <Link to={'/cs'}>
        <img
          className="w-[24px] h-[24px]"
          width={24}
          height={24}
          src={help}
          alt="더보기"
          role="presentation"
        />
      </Link>
    </div>
  );
}

export default Header;
