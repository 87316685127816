export const convertCampaignParameter = (campaign) => {
  const partiNo = campaign?.PartiTypeCode;
  const campaignKey = campaign?.Key;

  const isWebtoon = partiNo === 53;
  const isNews = partiNo === 46;
  const isCPM = partiNo === 43;

  const RVCampaignKey = '559551593';
  const RCPCCampaignKey = '958189455';
  const isRV = campaignKey === RVCampaignKey;
  const isRCPC = campaignKey === RCPCCampaignKey;

  const newsCampaignParams = isNews
    ? {
        id: campaign?.Id || null,
        reward_condition_sec: campaign?.RewardConditionSec || null,
        news_landing_web_browser: campaign?.NewslandingWebBrowser || null,
        click_url: campaign?.ClickUrl || null,
      }
    : [];

  const webToonCampaignParams = isWebtoon
    ? {
        id: campaign?.Id || null,
        reward_condition_sec: campaign?.RewardConditionSec || null,
        series: campaign?.Series || null,
        landing_url: campaign?.LandingUrl || null,
        // prev: campaign?.Prev,
        // next: campaign?.Next,*
        click_url: campaign?.ClickUrl || null,
      }
    : [];

  const cpmCampaignParams = isCPM
    ? {
        id: campaign?.Id || null,
        impression_urls: campaign?.ImpressionURLs || null,
        click_tracking_urls: campaign?.ClickTrackingURLs || null,
        click_url: campaign?.ClickUrl || null,
        // custom_param: campaign?.IntegrationId || null,
      }
    : [];

  const rewardString =
    campaign?.RewardDetail?.Quantity || campaign?.Reward || campaign?.Point || null;
  const parsedReward = typeof rewardString === 'string' ? parseInt(rewardString, 10) : rewardString;

  const commonCampaignParams = {
    integration_type_no: campaign?.IntegrationTypeCode || null,
    participation_type_no: campaign?.PartiTypeCode || null,
    auth: campaign?.Auth || null,
    campaign_key: campaign?.Key || null,
    title: campaign?.FeedTitle || campaign?.Title || null,
    description: campaign?.FeedDesc || campaign?.Description || null,
    reward: parsedReward,
    package_name: campaign?.PackageName || null,
    redirect_url: campaign?.RedirectURL || null,
    custom_param:
      campaign?.IntegrationId || campaign?.IntegrationPlacementId || campaign?.ScriptId || null,
  };

  const RVParams = isRV
    ? {
        ssp_app_key: campaign?.IntegrationAppKey,
        ssp_placement_id: campaign?.IntegrationPlacementId,
        campaign_key: RVCampaignKey,
        integration_type_no: 40,
        participation_type_no: 74,
      }
    : [];

  const RCPCParams = isRCPC ? { script_id: campaign?.ScriptId } : [];

  const campaignParams = {
    ...newsCampaignParams,
    ...webToonCampaignParams,
    ...cpmCampaignParams,
    ...commonCampaignParams,
    ...RVParams,
    ...RCPCParams,
  };

  return campaignParams;
};
