import { liveInstance, stgInstance } from './instance';

export function getCampaignList(params) {
  return liveInstance.post('/sdk/media/offerwall', JSON.stringify(params));
}

export function getCPMCampaignList(params, isTest) {
  const instance = isTest ? stgInstance : liveInstance;
  return instance.post('/sdk/media/CPMCampaign', JSON.stringify(params));
}

export function getRVInfoList(params, isTest) {
  const instance = isTest ? stgInstance : liveInstance;
  const { mediaKey, adid, idfa, usn } = params;
  return instance.get(
    `/sdk/media/rvInfo?mediaKey=${mediaKey || ''}&adid=${adid || ''}&idfa=${idfa || ''}&usn=${
      usn || ''
    }`
  );
}

export function participateCampaign(params, isTest) {
  const instance = isTest ? stgInstance : liveInstance;
  return instance.post('/sdk/media/join', JSON.stringify(params));
}

export function completeCampaign(params, isTest) {
  const instance = isTest ? stgInstance : liveInstance;
  return instance.post('/common/conversion/complete', JSON.stringify(params));
}
